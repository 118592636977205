import { useLocation } from "react-router-dom";
import './Footer.scss';
import projektUe from './../../images/projekt-ue.png';

const Footer = () => {
  const { pathname } = useLocation();
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      {pathname == "/" ? <img src={ projektUe } className="footer__ue" alt="Dofinansowanie UE" /> : ''}
      <div className="footer__row">
        <span>®KodujMata {currentYear}</span>
        <div className="footer__links">
          <a
            className="footer__link"
            href="https://kodujmata.pl/polityka-prywatnosci/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Polityka Prywatności
          </a>
          <a
            className="footer__link"
            href="https://kodujmata.pl/sklep/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Sklep KodujMata
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer;