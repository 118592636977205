import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { scenarioChange, categoryChange, flowChange, switchContentUpdated, updateScenarios, updateCategories, updateElements } from '../../reducer';
import Element from '../Element/Element';
import MenuLink from '../MenuLink/MenuLink';
import Search from '../Search/Search';
import './Menu.scss';
import { useState, useEffect } from 'react';
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const Menu = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const {board, activeGroup, activeFlow, activeElement, contentUpdated, scenarios, categories, elements} = useSelector((state) => state);

  const hasGroup = (scenario, group) => {
    return scenario.groups.includes(group);
  }

  const hasCategory = (category, scenario) => {
    return scenario.categories.includes(category.slug);
  }

  const inCategory = (element, category) => {
    return element.category == category.slug;
  }

  const properSize = (scenario) => {
    return scenario.boardSizes.includes(board.length);
  }

  const scenarioAndCategoryReset = () => {
    dispatch(scenarioChange(null));
    dispatch(categoryChange(null));
    dispatch(flowChange('play'));
  }

  const filterScenarios = (scenarios) => {
    return scenarios
      .filter(scenario => hasGroup(scenario, activeGroup))
      .filter(scenario => properSize(scenario, board.length));
  }

  let filteredScenarios = scenarios && filterScenarios(scenarios);
  let filteredElements = elements && elements.elements.filter(element => hasGroup(element, activeGroup));

  useEffect(() => {
    !contentUpdated && updateContent();
  }, []);

  const updateContent = () => {
    const categoriesRequest = fetch(
      'https://aplikacja.kodujmata.pl/backend/wp-json/kodujmata/v1/categories',
      {
        methods: 'GET',
        headers : {
          'Content-Type':'application/json'
        }
      })
      .then(response => response.json());

    const scenariosRequest = fetch(
      'https://aplikacja.kodujmata.pl/backend/wp-json/kodujmata/v1/scenarios',
      {
        methods: 'GET',
        headers : {
          'Content-Type':'application/json'
        }
      })
      .then(response => response.json());

    const elementsRequest = fetch(
      'https://aplikacja.kodujmata.pl/backend/wp-json/kodujmata/v1/elements',
      {
        methods: 'GET',
        headers : {
          'Content-Type':'application/json'
        }
      })
      .then(response => response.json());

    setLoading(true);

    Promise.all([categoriesRequest, scenariosRequest, elementsRequest])
      .then(response => {
        const [response1, response2, response3] = response;
        dispatch(updateCategories(response1));
        dispatch(updateScenarios(response2));
        dispatch(updateElements(response3));
        dispatch(switchContentUpdated(true));
        setLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  return(
    <>
      {loading && <LoadingScreen />}
      <ul className="menu" key="main-menu">
        {categories && categories.map((category) => (
          <li key={category.slug}>
            <MenuLink key={category.slug} item={category} arrow={activeFlow == 'scenarios' ? true : false} chooseOption="scenarios" />
            {activeFlow == 'scenarios' ?
              <ul className="menu__list" key={`list-${category.slug}`}>
                {category.sub_categories.map((subcategory) => (
                  <li key={subcategory.slug}>
                    {filteredScenarios.filter(scenario => hasCategory(subcategory, scenario)).length > 0 ?
                      <>
                        <MenuLink key={subcategory.slug} item={subcategory} classModifier="menu__link--light" arrow={true} />
                        {filteredScenarios.filter(scenario => hasCategory(subcategory, scenario)).length > 0 ?
                            <ul className="menu__list" key={`list-${subcategory.name}`}>
                              {
                                filteredScenarios.filter(scenario => hasCategory(subcategory, scenario)).map((scenario) => (
                                  <li key={scenario.slug}>
                                    <MenuLink key={scenario.slug} item={scenario} subcategory={subcategory.name} classModifier="menu__link--orange" chooseOption="category" />
                                  </li>
                                ))
                              }
                            </ul>
                          :
                            ''
                        }
                      </>
                    :
                      ''
                    }
                  </li>
                ))}
              </ul>
            :
              ''
            }
          </li>
        ))}
        {activeFlow == 'play' ?
          <li>
            <Link
              to="/board-size"
              className={`menu__link`}
              onClick={() => scenarioAndCategoryReset()}
            >
              Zmień rozmiar planszy
            </Link>
          </li>
        :
          ''
        }
        <li>
          <Link
            to="/playground"
            className={`menu__link ${activeFlow == 'play' ? 'menu__link--active' : ''}`}
            onClick={() => scenarioAndCategoryReset()}
          >
            Układaj
          </Link>
          {activeFlow == 'play' ?
            <>
              <Search elements={filteredElements} activeElement={activeElement}/>
              <ul className="menu__elements-list" key={`list-elements-categories`}>
                {elements && elements.elements_categories && elements.elements_categories.map((category) => (
                  <li key={`element-category-${category.slug}`}>
                    <MenuLink key={category.slug} arrow={true} item={category} />
                    <ul className="menu__elements" key={`list-elements`}>
                      {elements.elements && elements.elements.filter(element => inCategory(element, category)).map(element => (
                        <Element
                          key={`element-${element.slug}`}
                          item={element}
                          classModifier={activeElement == element ? ' menu__item--active' : ''}
                        />
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </>
          :
            ''
          }
        </li>
      </ul>
    </>
  )
};

export default Menu;
